import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { isLoggedIn, msalConfig, useLogin } from "./authConfig";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";

var layout;
console.log("checking msal...");
var msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    layout = (
        <MsalProvider instance={msalInstance}>
            <Layout />
        </MsalProvider>
    );

    initializeIcons();
    const Eddie = React.lazy(() => import("./pages/eddie/Eddie"));
    const router = createHashRouter([
        {
            path: "/",
            element: layout,
            children: [
                {
                    index: true,
                    path: "/",
                    element: <Chat />
                },
                {
                    path: "qa",
                    lazy: () => import("./pages/ask/Ask")
                },
                {
                    path: "eddie",
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Eddie />
                        </Suspense>
                    )
                },
                {
                    path: "testing",
                    lazy: () => import("./pages/testing/Testing")
                },
                {
                    path: "*",
                    lazy: () => import("./pages/NoPage")
                }
            ]
        }
    ]);

    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
});
